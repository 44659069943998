.author {
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;

  &--images {
    flex-wrap: wrap;
  }

  &__image {
    flex: 0 0 7rem;
    height: 7rem;
    background-size: cover;
    background-position: center top;
    border-radius: 50%;

    ^&--images & {
        margin-right: .75rem;
        margin-bottom: .75rem;
    }

    @media (--S_and_up) {
      flex: 0 0 11rem;
      height: 11rem;

      ^&--images & {
        flex: 0 0 8rem;
        height: 8rem;
      }

    }
  }

  &__detail {
    margin-left: 2rem;

    @media (--S_and_up) {
      margin-left: 3rem;
    }
  }

  &__name {
    display: block;
    color: var(--color-blue);
    font-weight: bold;
    margin: 0;

    &--no-link {
      color: var(--color-darkgrey);
      &:hover {
        text-decoration: none;
      }
    }
  }
}
