@import "layout.css";
@import "components/header.css";

.banner {
  position: relative;
  background-size: cover;
  background-position: center;
  /* default background image: */
  background-image: url(/images/site/header_2.jpg);

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--zindex-default);
    background-color: rgba(0,0,0,.5);
  }

  &--no-fade {
    &:before {
      display: none;
    }
  }

  &--box {
    margin: 4.5rem 0;
  }

  p { margin: 0; }

  &__content {
    position: relative;
    z-index: var(--zindex-content);
    padding: calc(var(--header-height-up_to_S) + 8rem) var(--page-spacing--up_to_S) 10rem;

    @media (--S_and_up) {
      padding: calc(var(--header-height-S_and_up) + 14rem) 0 13rem;
      lost-column: 8/12;
      lost-offset: 2/12;
    }

    .button {
      margin-top: 4rem;
    }

    .banner--text-only & {
      @media (--S_and_up) {
        padding-bottom: 22rem;
      }
    }

    .banner--slim & {
      padding-top: 6rem;
      padding-bottom: 6rem;

      @media (--S_and_up) {
        padding-top: 16rem;
        padding-bottom: 16rem;
      }
    }

    .banner--box & {
      @media (--S_and_up) {
        lost-column: 4/12;
        lost-offset: 1/12;
      }
    }
  }

  .heading {
    @apply --text-medium;

    @media (--S_and_up) {
      @apply --text-banner;
    }

    em {
      font-style: normal;
      color: var(--color-yellow);
    }
  }
}
