@import "colors.css";
@import "properties.css";

:root {
  --button: {
    color: var(--color-white);
    @apply --text-copy;
    display: inline-block;
    height: 42px;
    padding: 6px 16px;
    border: 2px solid var(--color-white);
    border-radius: 60px;

    &:hover {
      background-color: color(var(--color-white) alpha(-80%));
      text-decoration: none;
    }
    &:active {
      @apply --button--active;
    }
  }

  --button--small: {
    @apply --button;
    @apply --text-small;
    height: 30px;
    padding: 1px 12px;
  }

  --button--active: {
    color: var(--color-black);
    background-color: var(--color-white);
  }
}

.button {
  @apply --button;

  &--dark {
    color: var(--color-black);
    border-color: var(--color-black);
  }
}
