@import "grid.css";

:root {
  --margin-top: -3em;
}

.cards {
  &--tabs {
    position: relative;
    margin-bottom: 3rem;
  }
  @media (--S_and_up) {
    lost-flex-container: row;
    margin: lost-vars('gutter') lost-vars('gutter') 0;
  }
}

.card {
  background-color: var(--color-white);

  @media (--S_and_up) {
    lost-column: 1/3 flex;
    margin-bottom: 3.5rem;
    padding: 3rem;

    .cards--tabs & {
      padding: 0 3rem 3rem;
    }
  }

  @media (--up_to_S) {
    .cards--tabs & {
      display: block;
    }
    &__title {
      .cards--tabs & {
        margin: 0;
        position: absolute calc(var(--margin-top) + 1px) 0 auto 0;
        z-index: var(--zindex-content);
        width: calc(100% / 3 - .25rem);
        display: inline-block;
        @apply --text-copy;
        text-align: center;
        background-color: var(--color-blue);

        label {
          display: block;
          padding: 1.5rem;
          cursor: pointer;
          color: var(--color-white);
        }
      }
      ^& [type=radio]:checked ~ &,
      ^&:hover & {
        .cards--tabs & {
          background-color: var(--color-white);
          label {
            color: var(--color-black);
          }
        }
      }
      ^&:nth-child(2) & {
        .cards--tabs & {
          left: calc(100% / 3 + .125rem) ;
        }
      }
      ^&:nth-child(3) & {
        .cards--tabs & {
          left: auto;
        }
      }
    }

    &__heading {
      @apply --text-copy;
    }

    &__content {
      .cards--tabs & {
        display: none;
        @apply --line-reset;
      }
      ^& [type=radio]:checked ~ & {
        .cards--tabs & {
          padding: 2.5rem 3.5rem;
          display: block;
          position: relative;
          z-index: calc(var(--zindex-content) + 1);
          background-color: var(--color-white);
        }
      }
    }
  }

  &--intro {
    .card__content {
      ul:not(.list) {
        padding-left: 3rem;
      }
    }
  }

  &--list {
    padding: 3rem 3.5rem 0;

    &:last-child {
      .card__author {
        @apply --line-reset;
      }
    }

    .card__content {
      @apply --genericStyles;
    }

    @media (--up_to_S) {
      .card__author {
        @apply --line;
        padding-bottom: 3rem;
        margin-top: 4rem;
        margin-bottom: 0;
      }
    }

    @media (--S_to_M) {
      lost-column: 1/2 flex;
    }

    @media (--S_and_up) {
      padding: 0;
      display: flex;
      flex-direction: column;

      .card {
        &__title,
        &__content {
          padding-left: 3rem;
          padding-right: 3rem;
        }

        &__title {
          margin-top: 3rem;
        }

        &__heading {
          margin-bottom: 0;
        }

        &__author {
          margin-top: auto;
          margin-bottom: 0;
          padding: 3rem;
        }
      }
    }
  }

  /* Reference: https://css-tricks.com/functional-css-tabs-revisited/ */
  .cards--tabs & {
    [type=radio] {
      display: none;
    }

    .svg--twitter {
      display: inline-block;
      width: 26px;
      height: 26px;
    }
  }

  &__image {
    display: none;
    @media (--S_and_up) {
      display: block;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 240px;
    }
  }

  &__title {
    @apply --text-headline;
  }
}
