@import "list.css";
@import "colors.css";

.icon-list {
  margin-bottom: 5rem;

  &__item {
    display: inline-block;
    vertical-align: top;
    width: calc(42/8)rem;
    height: calc(42/8)rem;
    border-radius: 50%;
    margin-right: 3.5rem;
    margin-bottom: 1.75rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    display: block;
    color: var(--color-white);
    width: 100%;
    height: 100%;
    border-radius: inherit;
  }
}
