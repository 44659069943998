@import "grid.css";
@import "layout.css";
@import "colors.css";
@import "button.css";
@import "zindexes.css";

:root {
  --header-height-up_to_S: calc(66/8)rem;
  --header-height-S_and_up: calc(90/8)rem;;
}

.header {
  padding: 2rem var(--page-spacing--up_to_S) 0;
  height: var(--header-height-up_to_S);
  position: fixed 0 0 auto;
  z-index: var(--zindex-header);
  display: flex;
  justify-content: space-between;

  @media (--S_and_up) {
    height: var(--header-height-S_and_up);
    padding: 3rem var(--page-spacing--S_and_up) 0;
  }

  transition: background-color .5s ease-in;
  &--active {
    background-color: var(--color-blue);
  }

  &__logo {
    width: 8.5rem;
    height: 3.5rem;

    @media (--S_and_up) {
      width: 14rem;
      margin-top: calc(5/8)rem;
    }
  }

  .header-navigation {
    white-space: nowrap;

    &__item {
      display: inline-block;
    }

    &__link {
      @apply --button--small;
      margin-left: 1rem;

      @media (--S_and_up) {
        @apply --button;
        margin-left: 2.5rem;
      }

      &:hover,
      &--active {
        @apply --button--active;
      }
    }
  }
}
