.foundation {
  margin: 4.5rem 0;
}

.references {
  margin-bottom: 4.5rem;

  &__headline {
    display: none;
    padding: 1.25rem 3rem;
    color: var(--color-white);
    background-color: var(--color-purple);
    margin: 4.5rem 0 0;

    @media (--S_and_up) {
      padding-left: var(--page-spacing--S_and_up);
    }
  }
}
